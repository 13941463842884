@import '../../../assets/styles/sass/main.scss';

.rootContainer {
  border: none !important;
}

.rowSpacing {
  padding-bottom: 5px !important;
  box-sizing: content-box !important;
}

.table_header {
  justify-content: center;
  text-transform: capitalize;
  font-family: $font-secondary;
  color: $primary-default;
  font-size: 14px;
  font-weight: 400;
}

.cell_container {
  align-items: center !important;
  // justify-content: center !important;
  font-family: $font-secondary;
  color: $primary-default;
  font-size: 16px;
  font-weight: 400;
  outline: none !important;
  height: auto;
  max-height: auto !important;
}

.header_outline {
  outline: none !important;
}

.columnSeparator {
  display: none !important;
}

.menuIcon {
  display: none !important;
}

// .rowStyling {
//   border-left: 4px solid #C793BE;
//   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
//   border-radius: 2px;
// }

.default_rowStyling {
  border-left: 4px solid $pink-light;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.default {
  border-left: 4px solid transparent;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  &.pink {
    border-color: $pink-dark;
  }
  &.green {
    border-color: $primary-300;
  }
  &.purple {
    border-color: $purple-dark;
  }
  &.caution {
    border-color: $caution-900;
  }
  &.warning {
    border-color: $warning-300;
  }
  &.warning_dark {
    border-color: $warning-default;
  }
  &.other {
    border-color: $grey-100;
  }
}

.MuiDataGrid-root .sort_header {
  display: flex;
  align-items: center;
}

.MuiDataGrid-root .sort_header .MuiDataGrid-sortIcon {
  margin-left: 4px;
}

.grid_container {
  gap: 1rem;
  height: 100%;
  background-color: $white;
  padding: 2rem;
  overflow-y: auto;
  .search_header {
    max-width: 640px;
  }
}

// new csss
.page {
  min-width: 26px !important;
  color: $primary-default !important;
  &.selected {
    background-color: $primary-default !important;
    color: #fff !important;
    border-radius: 0 !important;
  }
}

.icon {
  color: $primary-default !important;
}
