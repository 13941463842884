/* primary colors */
$primary-100: #E8F9F0; //--theme-primary-light
$primary-300: #A3CAB5; //--theme-primary-medium
$primary-default: #546C5F; //--theme-primary
$primary-700: #3C4D43; //--theme-primary-dark
$primary-900: #28332D;

/* secondary colors */
$secondary-100: #FCFFF3; //--theme-secondary-light
$secondary-300: #F8FFE9; //--theme-secondary-medium
$secondary-default: #F1FCD7; //--theme-secondary
$secondary-700: #D9EAB0; //--theme-secondary-dark
$secondary-900: #A8BB79;

/* background colors */
$bg-100: #F8FDFB;
$bg-300: #F7FCED;
$bg-default: #E1E5D9;
$bg-700: #F5F8EF;

/* grey color*/
$grey-900: #1a1a1a; //--grey-extra-dark
$grey-700: #4d4d4d; //--grey-dark
$grey-500: #808080; //--grey-standard
$grey-300: #b3b3b3; //--grey-medium
$grey-100: #e6e6e6; //--grey-light
$grey-50: #f7f7f7; //--grey-extra-light

/*bW*/
$black:#000000;
$white:#FFFFFF;

/*Terinary colors*/
$pink-dark:#C793BE;
$pink-light:#E9D4E5;

$orange-light:#F5DFD3;
$orange-dark:#E6AF90;

$blue-light:#BFD7EE;
$blue-dark:#609CD3;

$purple-light:#D5D6E9;
$purple-dark:#9698C7;

$peach-light:#EEDFDF;
$peach-dark:#D4AEAE;

/*warning colors*/
$warning-900:#8A1E08;
$warning-700:#B32306;
$warning-default:#DC3716;
$warning-300:#F2ADA5;
$warning-100: #FCEEED;

/*caution*/
$caution-100: #FFF6E3;
$caution-300: #FFE0A3;
$caution-default: #FFC44D;
$caution-700: #A17217;
$caution-900: #D29822;

/*success*/
$success-100: #EDF7F2;
$success-300: #89CCA9;
$success-default: #0F945F;
$success-700: #0D6027;
$success-900: #07794C;

/*links*/
$link-900: #276EC4;
$link-700: #0B3A73;
$link-default: #175299;
$link-light: #D9EAFF;






