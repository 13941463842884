@import "../../../assets/styles/sass/variables/variable";

.nocontent_wrapper {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .image {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      &.small {
        max-width: 76px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .title {
      font-size: 14px;
      font-weight: 600;
      color: $grey-500;
      margin-bottom: 3px;
    }
    .description {
      color: $grey-500;
      font-size: 12px;
      text-align: center;
    }
  }
}
