@import '../../../assets/styles/sass/main.scss';

.toaster_container{
    padding: 12px 8px;
    position: fixed;
    top: 60px;
    right: 60px;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 4px;

    &.success{
        background-color: $success-100;
    } 
    &.error{
        background-color: $warning-100;
    } 
    &.info{
        background-color: $link-light;
    } 
    &.warning{
        background-color: $caution-100;
    }
    .toast_content{
        p {margin-bottom: 0; color: $grey-700; font-weight: 600; font-size: 14px;}
    }
}