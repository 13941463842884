@import '../../../assets/styles/sass/main.scss';
.heading{
    display: flex;
    gap: .8rem;
    align-items: center;
    .title{
        color: $primary-700;
        font-size: 24px;
        text-transform: capitalize;
        margin-bottom: 0;
        &.theme{
            color: $primary-default;
        }
    }
}