.page_wrapper{
    width: 100%;
    gap: 6px;
    height: 100%;
    background-color: #fff;
    padding: 1rem;
    position: relative;
    overflow-y: auto;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    &.inverted{
        background-color: transparent;
        padding: 6px;
    }
}