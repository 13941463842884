.home,
.reports,
.products {
  display: flex;
  /* height: 90vh; */
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  background-color: #f7f7f7;
  width: 100%;
}

.mainContainer {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
