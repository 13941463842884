@import '../mixins/remConverter';

$font-primary: "Playfair Display", serif;
$font-secondary: "Poppins", sans-serif;
.fs-10 {
    font-size: em(10);
}
.fs-12 {
    font-size: em(12);
}
.fs-14 {
    font-size: em(14);
}
.fs-16 {
    font-size: em(16);
}
.fs-20 {
    font-size: em(20);
}
.fs-24 {
    font-size: em(24);
}
.fs-32 {
    font-size: em(32);
}
.fs-38 {
    font-size: em(38);
}
.fs-48 {
    font-size: em(48);
}
.fs-60 {
    font-size: em(60);
}
.fs-76 {
    font-size: em(76);
}

