@import '../../../assets/styles/sass/variables/variable';

@mixin avatarStyle ($bgColor, $color) {
    background-color: $bgColor;
    color: $color;
  }

.nAvatar{
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include avatarStyle($grey-100, $black);

    &.light_green {
    @include avatarStyle($primary-100, $black);
    }
    &.green {
    @include avatarStyle($success-300, aliceblue);
    }
    &.dark_green {
    @include avatarStyle($primary-default, aliceblue);
    }
    &.light_purple {
    @include avatarStyle($purple-light, $black);
    }
    &.purple {
    @include avatarStyle($purple-dark, $black);
    }
    &.light_pink {
    @include avatarStyle($pink-light, $black);
    }
    &.pink {
        @include avatarStyle($pink-dark, $black);
    }
    &.light_orange {
    @include avatarStyle($orange-light, $black);
    }
    &.caution {
    @include avatarStyle($caution-900, $black);
    }
    &.warning {
    @include avatarStyle($warning-300, $black);
    }
    &.warning_dark {
    @include avatarStyle($warning-default, $black);
    }
    &.primary {
    background-color: $link-900;
    }

    &.xs{
        width: 36px;
        height: 36px;
        border: none; 
    }

    &.sm{
        width: 56px;
        height: 56px;
        border: none;
    }
    &.md{
        max-width: 130px;
        max-height: 130px;
    }
    &.lg{
        max-width: 200px;
        max-height: 200px;
    }
}

 